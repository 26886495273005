import React from 'react'
import { Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import clsx from 'clsx'
import QuantitySelector from './QuantitySelector'
import SizeModal from '../Sizes/SizeModal'
import { CartReducerState } from '@/redux/reducers/cartReducer'
import { StockReducerState } from '@/redux/reducers/stockReducer'
import { ShopConfig } from '@/interfaces/common/shopConfig'
import { TakeBackReducerState } from '@/redux/reducers/takeBackReducer'
import { PrismicGeneralData } from '@/interfaces/common/prismicGeneralConfig'
import { Product } from '@/typings/base'
import { PrismicProduct } from '../../interfaces/common/prismicProduct'

type ProductAddToCartProps = {
  hidePrice: boolean
  availability: boolean
  product: Product
  addButton: boolean
  classes: any
  index: number
  setOpenModalAndTrack: () => void
  openModal: boolean
  stock: StockReducerState
  setOpenModal: (open: boolean) => void
  shopData: ShopConfig
  cart: CartReducerState
  addProductWithSize: (
    variantCode: string,
    variantName: string,
    quantity: number
  ) => void
  thumbRatio: string
  thumbWidth: number
  settings: PrismicGeneralData
  currentUrl: string
  quantity: number
  onQuantityChange: (quantity: number) => void
  isSecondHandTaxon: boolean
  takeback: any
  takeBackLP: TakeBackReducerState
  productSettings: PrismicProduct
}

const ProductCardAddToCart = ({
  hidePrice,
  availability,
  product,
  addButton,
  classes,
  index,
  setOpenModalAndTrack,
  openModal,
  stock,
  setOpenModal,
  shopData,
  cart,
  addProductWithSize,
  thumbRatio,
  thumbWidth,
  settings,
  currentUrl,
  quantity,
  onQuantityChange,
  isSecondHandTaxon,
  takeback,
  takeBackLP,
  productSettings,
}: ProductAddToCartProps) => {
  const closeSizesModal = () => {
    setOpenModal(false)
  }
  return (
    !hidePrice &&
    ((availability &&
      product &&
      addButton &&
      typeof product.variants !== 'undefined' &&
      Object.keys(product.variants).length > 1) ||
    isSecondHandTaxon ? (
      <div className={clsx('productCard__add', classes.more)} id="add_button">
        <Button
          data-testid={`buttonOpenModale-${index}`}
          variant="contained"
          className={
            availability
              ? clsx('productCard__cta', classes.addButton)
              : clsx('productCard__cta', classes.addButtonDisabled)
          }
          disabled={!availability}
          onClick={setOpenModalAndTrack}
        >
          <AddIcon />
        </Button>
        <SizeModal
          open={openModal}
          stock={stock}
          handleClose={closeSizesModal}
          products={product}
          text={shopData}
          type={'product'}
          cart={cart}
          onQuantityChange={addProductWithSize}
          thumbRatio={thumbRatio}
          thumbWidth={thumbWidth}
          settings={settings}
          currentUrl={currentUrl}
          isSecondHandTaxon={isSecondHandTaxon}
          takeback={takeback}
          takeBackLP={takeBackLP}
          productSettings={productSettings}
        />
      </div>
    ) : (
      /* Product has 1 variant */
      availability &&
      addButton && (
        <>
          {settings?.has_quantity_selector ? (
            <div className={clsx('productCard__add', classes.more)}>
              <QuantitySelector
                small
                disable={cart.loading}
                value={quantity}
                handleChange={onQuantityChange}
                min={0}
                max={
                  product?.variants[Object.keys(product?.variants)[0]].maxStock
                    ? product?.variants[Object.keys(product?.variants)[0]]
                        .maxStock
                    : null
                }
                variantSku={
                  product?.variants[Object.keys(product?.variants)[0]].sku
                }
              />
            </div>
          ) : (
            <>
              <div className={clsx('productCard__add', classes.more)}>
                <Button
                  data-testid={`buttonOpenModale-${index}`}
                  variant="contained"
                  className={
                    availability
                      ? clsx('productCard__cta', classes.addButton)
                      : clsx('productCard__cta', classes.addButtonDisabled)
                  }
                  disabled={!availability}
                  onClick={() => onQuantityChange(quantity + 1)}
                >
                  <AddIcon />
                </Button>
              </div>
            </>
          )}
        </>
      )
    ))
  )
}

export default ProductCardAddToCart
