import React, { useMemo, useState } from 'react'
import { makeStyles, createStyles, Theme, Typography } from '@material-ui/core'
import Button from '../Button/Button'
import { Cart, ProductToAdd, Products } from '../../typings/base'
import CMSText, { isPrismicText } from '../Global/CMSText'
import { connect } from 'react-redux'
import { useCart } from '../../hooks/useCart'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import GlobalModal from '../Global/GlobalModal'
import SecondHandBackInStockAlertButton from '../BackInStockAlert/SecondHandBackInStockAlertButton'
import { GetMapDispatchTypes } from '../../interfaces/common/redux'
import { RootState } from '../../redux/reducers/rootReducer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    sizes: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: theme.spacing(1),
    },
    sizes__label: {
      paddingRight: theme.spacing(1),
    },
    state__label: {
      paddingRight: theme.spacing(1),
    },
    sizes__list: {
      padding: theme.spacing(0.25, 0.25, 0.25, 0),
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(1),
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
    addButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-between',
        marginTop: theme.spacing(1),
      },
    },
    button: {
      display: 'inline-flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    sizeButton: {
      height: theme.spacing(4),
      padding: theme.spacing(1, 2),
      margin: theme.spacing(0.5, 0.5, 0.5, 0),
      minWidth: 45,
      backgroundColor: theme.extraColors.greyLight,
      '&:hover': {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      '&.sold-out': {
        opacity: 0.5,
        color: theme.palette.error.main,
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.5, 1),
        margin: theme.spacing(0.25),
      },
    },
    selected: {
      height: theme.spacing(4),
      padding: theme.spacing(1, 2),
      margin: theme.spacing(0.5, 0.5, 0.5, 0),
      minWidth: 45,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.black,
      '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.5, 1),
        margin: theme.spacing(0.25),
      },
    },
    info__icon: {
      width: 15,
      '&:hover': {
        opacity: 0.5,
        cursor: 'pointer',
      },
    },
    statesModal: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    warrantyText: {
      margin: theme.spacing(1),
    },
  })
)

type TProps = {
  products: Products
  settings: any
  t: any
  getTakeBackStock: any
  takeback: any
  currentItem: any
  cart: Cart
  productSettings: any
  selectedQuality: string
  handleStateSelected: (quality: string) => void
  handleSizeSelected: (sku: string) => void
  currentSizeQualities: any
  showQualities: boolean
  noMoreStockAvailable: boolean
  sku: string
  currentVariantCode: string
  currentQualityInCart: (quality: string, sku: string, cart: any) => number
  setSelectedQuality: any
  availableSkus: string[]
  addedToCart?: () => void
} & ReturnType<typeof mapStateToProps> &
  GetMapDispatchTypes<typeof mapDispatchToProps>

const TakeBackSize = ({
  products,
  settings,
  t,
  takeBackProps,
  takeback,
  noMoreStockAvailable,
  currentItem,
  cart,
  selectedQuality,
  handleStateSelected,
  handleSizeSelected,
  currentSizeQualities,
  showQualities,
  sku,
  currentVariantCode,
  productSettings,
  currentQualityInCart,
  setSelectedQuality,
  availableSkus,
  addedToCart,
}: TProps) => {
  const { addProductToCart } = useCart()
  const classes = useStyles()
  const showAllSize = false
  const showAllQualities = false
  const isStatesInformationsModal = takeback?.data?.states_informations_modal
  const [openStatesModal, setOpenStatesModal] = useState(false)

  const [variants, isAllVariantsInStock, allVariantsNotInStock] =
    useMemo(() => {
      const productVariants = Object.values(products?.variants)
      let _variants = []
      if (showAllSize) {
        // if you want display all sizes
        _variants = productVariants
      } else {
        // if you want display available sizes only
        _variants = productVariants.filter((variant) =>
          takeBackProps?.takeBackStock?.some(
            (stock) =>
              stock.lizeeSKU === variant.sku &&
              stock.productsByQuality.length > 0
          )
        )
      }
      const _isAllVariantsInStock = productVariants.length === _variants.length
      const allVariantsInStock = productVariants.filter((variant) =>
        takeBackProps?.takeBackStock?.some(
          (stock) =>
            stock.lizeeSKU === variant.sku && stock.productsByQuality.length > 0
        )
      )
      const _allVariantsNotInStock = productVariants.filter(
        (variant) =>
          !allVariantsInStock
            .map((allVariantInStock) => allVariantInStock.sku)
            .includes(variant.sku)
      )

      return [_variants, _isAllVariantsInStock, _allVariantsNotInStock]
    }, [products?.variants, showAllSize, takeBackProps?.takeBackStock])

  const handleCloseModal = () => {
    setOpenStatesModal(false)
  }

  const handleAddTakeBackProductToCart = () => {
    const numberInCart = currentQualityInCart(selectedQuality, sku, cart)

    const { puid } = currentSizeQualities.find(
      (currentQuality) => currentQuality.quality === selectedQuality
    )?.products[numberInCart]

    const data: ProductToAdd = {
      productCode: currentItem.code,
      secondHand: 'true',
      units: [
        {
          puid,
          condition: selectedQuality,
        },
      ],
    }

    if (!currentItem?.isSimpleProduct) {
      data.variantCode = currentVariantCode.toString()
    }

    setSelectedQuality('')
    addProductToCart(data)
    if (addedToCart) addedToCart()
  }

  const stockAlertSecondHand =
    takeback?.data?.take_back_stock_alert_enable && !isAllVariantsInStock

  return (
    <div className={classes.root}>
      {!noMoreStockAvailable ? (
        <>
          <div className={`sizes ${classes.sizes}`}>
            <Typography
              component={'div'}
              className={`size__label ${classes.sizes__label}`}
            >
              <strong>
                <CMSText
                  asText
                  data={settings?.data?.sizes}
                  defaultText={t('texts:general:sizes')}
                />
              </strong>
            </Typography>
          </div>
          <div className={`sizes__list ${classes.sizes__list}`}>
            {variants &&
              variants?.map((variant, index) => {
                return (
                  <Button
                    key={index}
                    size="small"
                    className={
                      variant.sku === sku
                        ? `sizes__btn selected ${classes.selected}`
                        : `sizes__btn ${classes.sizeButton}`
                    }
                    onClick={() => handleSizeSelected(variant.sku)}
                    data-testid={`btn-size-${index}`}
                    disabled={
                      (showAllSize &&
                        takeBackProps?.takeBackStock?.find(
                          (varSku) => varSku.lizeeSKU === variant.sku
                        )?.productsByQuality.length === 0) ||
                      !availableSkus.includes(variant.sku)
                    }
                  >
                    {variant.size}
                  </Button>
                )
              })}
            {/**stock alert second hand */}
            {stockAlertSecondHand && (
              <SecondHandBackInStockAlertButton
                prismicTakeback={takeback?.data}
                allVariantsNotInStock={allVariantsNotInStock}
              />
            )}
            {showQualities && (
              <div>
                <Typography
                  component={'div'}
                  className={`state__label ${classes.state__label}`}
                >
                  <strong>
                    <CMSText
                      asText
                      data={takeback?.data?.state_label}
                      defaultText={t('texts:takeBack:select_label_state')}
                    />
                  </strong>
                  {isStatesInformationsModal && (
                    <InfoIcon
                      fontSize="small"
                      onClick={() => setOpenStatesModal(true)}
                      className={classes.info__icon}
                    />
                  )}
                </Typography>
                <GlobalModal
                  open={openStatesModal}
                  onClose={handleCloseModal}
                  modalTitle={{
                    data: takeback?.data?.states_information_modal_title,
                    defaultText: t(
                      'texts:takeBack:take_back_states_information_modal_title'
                    ),
                  }}
                  customClass={'take_back_states_informations'}
                >
                  <div className={classes.statesModal}>
                    <div>
                      <Typography variant={'h4'}>
                        <CMSText
                          asText
                          data={takeback?.data?.perfectcondition}
                          defaultText={takeback?.data?.state_1}
                        />
                      </Typography>
                      <Typography>
                        <CMSText
                          asText
                          data={takeback?.data?.perfectconditiondefinition}
                          defaultText={takeback?.data?.state_1}
                        />
                      </Typography>
                    </div>
                    <div>
                      <Typography variant={'h4'}>
                        <CMSText
                          asText
                          data={takeback?.data?.verygoodcondition}
                          defaultText={takeback?.data?.state_1}
                        />
                      </Typography>
                      <Typography>
                        <CMSText
                          asText
                          data={takeback?.data?.verygoodconditiondefinition}
                          defaultText={takeback?.data?.state_1}
                        />
                      </Typography>
                    </div>
                    <div>
                      <Typography variant={'h4'}>
                        <CMSText
                          asText
                          data={takeback?.data?.goodcondition}
                          defaultText={takeback?.data?.state_1}
                        />
                      </Typography>
                      <Typography>
                        <CMSText
                          asText
                          data={takeback?.data?.goodconditiondefinition}
                          defaultText={takeback?.data?.state_1}
                        />
                      </Typography>
                    </div>
                  </div>
                </GlobalModal>
                {currentSizeQualities.map((quality, index) => {
                  const Btn = () => (
                    <Button
                      size="small"
                      className={
                        quality.quality === selectedQuality
                          ? `sizes__btn selected ${classes.selected}`
                          : `sizes__btn ${classes.sizeButton}`
                      }
                      disabled={quality.disabled}
                      onClick={() => handleStateSelected(quality)}
                    >
                      {quality.title}
                    </Button>
                  )

                  if (showAllQualities) {
                    return <Btn key={index} />
                  }
                  if (!quality.disabled) {
                    return <Btn key={index} />
                  }
                  return null
                })}
              </div>
            )}
          </div>
          <Button
            variant="contained"
            color="secondary"
            data-testid={'btn-addToCart'}
            id="btn-addToCart"
            disabled={
              selectedQuality === '' ||
              currentSizeQualities.find(
                (quality) => quality.quality === selectedQuality
              )?.disabled
            }
            className="sizes-add__btn"
            onClick={handleAddTakeBackProductToCart}
          >
            <CMSText
              asText
              data={productSettings?.data?.buy_button}
              defaultText={t('texts:single_product:buy_button')}
            />
          </Button>
        </>
      ) : (
        <>
          <Typography variant="h4" className={'product__unavailable'}>
            <CMSText
              asText
              data={productSettings?.data?.sold_out}
              defaultText={t('texts:packs:victim_of_success')}
            />
          </Typography>
          {/**stock alert second hand */}
          {stockAlertSecondHand && (
            <SecondHandBackInStockAlertButton
              prismicTakeback={takeback?.data}
              allVariantsNotInStock={allVariantsNotInStock}
            />
          )}
        </>
      )}
      {isPrismicText(takeback?.data?.warranty_text) && (
        <Typography
          component={'div'}
          variant={'caption'}
          className={(classes.warrantyText, 'warranty_text')}
        >
          <CMSText data={takeback?.data?.warranty_text} />
        </Typography>
      )}
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  takeBackProps: state.takeBack,
})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TakeBackSize)
