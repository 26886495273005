import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useProductStyles = makeStyles((theme: Theme) =>
  createStyles({
    prev: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    main: {
      paddingTop: theme.spacing(3),
    },
    carouselMain: {
      margin: theme.spacing(0, 0, 3),
    },
    carouselThumbs: {
      '& .glider.draggable': {
        cursor: 'default',
      },
    },
    carouselThumbsSmall: {
      '& .glider-prev': {
        left: theme.spacing(2),
      },
      '& .glider-next': {
        right: theme.spacing(2),
      },
    },
    carouselThumb: {
      opacity: 1,
      transition: theme.transitions.create('opacity'),
      '&:hover, &:focus': {
        opacity: 0.5,
        cursor: 'pointer',
      },
    },
    carouselThumbCurrent: {
      opacity: 0.5,
    },
    carouselThumbsImg: {
      borderRadius: theme.shape.borderRadius,
      cursor: 'pointer',
    },
    mediaMain: {
      borderRadius: theme.shape.borderRadius,
    },
    mediaGap: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(4),
      },
    },
    content: {
      marginTop: theme.spacing(2),
    },
    contentSticky: {
      [theme.breakpoints.down('md')]: {
        zIndex: theme.zIndex.snackbar,
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: theme.spacing(2, 2, 1),
        background: theme.palette.common.white,
        borderTop: `1px solid ${theme.extraColors.greyLight}`,
      },
      [theme.breakpoints.up('md')]: {
        position: 'sticky',
        top: theme.sizes.headerHeight + theme.spacing(2),
      },
    },
    contentDetailsSticky: {
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
      },
    },
    desc: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    smallText: {
      fontSize: '75%',
    },
    selectors: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    loadingImg: {
      display: 'block',
      maxWidth: '100%',
      height: 480,
      borderRadius: theme.shape.borderRadius,
    },
    loadingTitle: {
      margin: theme.spacing(0, 0, 3),
    },
    loadingBtn: {
      display: 'inline-block',
      margin: theme.spacing(3, 0),
      height: theme.spacing(6),
      borderRadius: theme.spacing(3),
    },
    related: {
      padding: theme.spacing(2, 0),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(1, 0, 6),
      },
    },
    relatedTitle: {
      margin: theme.spacing(0, 0, 2),
    },
    col: {},
    md5: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: '20%',
        flexBasis: '20%',
      },
    },
    associationRow: {
      marginBottom: theme.spacing(3),
    },
    priceSmall: {},
    breadCrumbActiveItem: {
      fontWeight: 600,
      cursor: 'pointer',
    },
  })
)
