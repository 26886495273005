import React, { useState, useEffect, useMemo, useCallback } from 'react'
import {
  makeStyles,
  createStyles,
  Typography,
  Theme,
  Chip,
} from '@material-ui/core'
import clsx from 'clsx'
import { connect } from 'react-redux'
import AddIcon from '@material-ui/icons/Add'
import QuantitySelector from './QuantitySelector'
import Button from '../Button/Button'
import SizeModal from '../Sizes/SizeModal'
import { GAEvent, Image, ProductAvailability } from '@/typings/base'
import {
  checkProductAvailability,
  checkStockQuantity,
  numberToPrice,
  truncateString,
} from '@/utils/utils'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { addToCartUtility } from '@/utils/addToCart'
import ImgKitHandler from '../ImgKitHandler/ImgKitHandler'
import { useRouter } from 'next/router'
import useShopStyles from './Shop.style'
import BuyAbleMention from './BuyAbleMention'
import ProductPriceFormat from './ProductPriceFormat'
import CMSText from '../Global/CMSText'
import { LayoutStyle } from '@/typings/base'
import Link from '../Link/Link'
import { useCart } from '@/hooks/useCart'
import { useTranslation } from 'react-i18next'
import { Product } from '@/interfaces/products/shop'
import { ShopConfig } from '@/interfaces/common/shopConfig'
import Price from './Price'
import { RootState } from '@/redux/reducers/rootReducer'
import { getLowestConditionByConditions } from '@/utils/filters'
import { StockElement } from '@/redux/reducers/stockReducer'
import Glider from 'react-glider'
import { ArrowForwardIos, ArrowBackIos } from '@material-ui/icons'
import { PrismicGeneralConfig } from '@/interfaces/common/prismicGeneralConfig'
import ProductCardAddToCart from './ProductCardAddToCart'
import { Product as TProduct } from '@/typings/base'
import { PrismicProduct } from '@/interfaces/common/prismicProduct'

const useStyles = makeStyles<Theme, LayoutStyle>((theme: Theme) =>
  createStyles({
    thumb: {
      borderRadius: theme.shape.borderRadius / 2,
      '&:not([src])': {
        background: theme.extraColors.greyLight,
        content:
          'url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==")',
      },
    },
    title: {
      minHeight: 33,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.2,
      textTransform: 'capitalize',
      '&>a': {
        color: 'inherit',
        textDecoration: 'none',
        '&:before': {
          zIndex: 1,
          content: "''",
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    description: {
      display: (LayoutStyle) => !LayoutStyle?.isDisplayedPrice && 'flex',
      justifyContent: (LayoutStyle) =>
        !LayoutStyle?.isDisplayedPrice && 'space-between',
      padding: theme.spacing(1),
    },
    row: {
      display: 'inline-flex',
      justifyContent: 'space-between',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    rowCta: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    rowCtaSecondHand: {
      justifyContent: 'flex-start',
      gap: '.5ch',
      marginTop: theme.spacing(1),
    },
    availability: {
      position: 'absolute',
      zIndex: 2,
      top: theme.spacing(2),
      left: '50%',
      transform: 'translateX(-50%)',
      padding: theme.spacing(1, 1.5),
      fontSize: 11,
      borderRadius: 36,
      textAlign: 'center',
    },
    smallAvalability: {
      position: 'absolute',
      zIndex: 2,
      top: theme.spacing(2),
      left: '50%',
      transform: 'translateX(-50%)',
      padding: theme.spacing(1, 1.5),
      fontSize: 8,
      borderRadius: 36,
      textAlign: 'center',
    },
    unavailable: {
      opacity: 0.7,
    },
    unavailableTitle: {
      color: theme.palette.common.white,
      background: theme.extraColors.grey,
    },
    enought: {
      color: theme.palette.ternary.main,
      background: theme.palette.ternary.light,
    },
    available: {
      color: theme.palette.common.white,
      background: theme.palette.primary.main,
    },
    rowButtons: {
      padding: theme.spacing(0),
      '& > *': {
        margin: theme.spacing(0.5),
      },
      '& > button': {
        flex: '1 1 210px',
      },
      '& > div': {
        flexGrow: 2,
        [theme.breakpoints.up('md')]: {
          order: 1,
        },
      },
    },
    more: {
      zIndex: 2, //VS link overlay
      position: 'relative',
    },
    addButton: {
      padding: 0,
      minWidth: 32,
      height: 32,
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
    addButtonDisabled: {
      padding: 10,
      minWidth: 0,
      backgroundColor: theme.extraColors.greyMedium + ' !important',
      color: theme.palette.common.white + ' !important',
    },
    price: {
      fontWeight: 700,
      marginTop: theme.spacing(1),
    },
    priceSecondHand: {
      marginTop: 0,
    },
    fixedHeightSmall: {
      height: 0,
    },
    productThumb: {
      top: 0,
      left: 0,
      right: 0,
      width: 'auto',
      margin: 'auto !important',
      bottom: 0,
      height: '100%',
      display: 'block',
      position: 'absolute',
      objectFit: 'contain',
    },
    oldPrice: {
      textDecoration: 'line-through',
    },
    originalPrice: {
      fontSize: 12,
      fontWeight: 700,
    },

    carouselContainer: {
      margin: theme.spacing(0),
      '& .glider-track': {
        width: '100% !important',
      },
      '& .glider-slide': {
        minWidth: '100%',
      },
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        zIndex: 10,
      },
    },

    variants: {
      width: '100%',
      position: 'absolute',
      bottom: 0,
      display: 'flex',
      gap: '4px',
      flexWrap: 'wrap',
      padding: '0.75rem 0.25rem',
      justifyContent: 'center',
    },

    variantChip: {
      backgroundColor: theme.extraColors.greyLight,
      '& span': {
        whiteSpace: 'nowrap',
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.5, 1),
        margin: theme.spacing(0.25),
      },
    },
  })
)

type Tprops = ReturnType<typeof mapStateToProps> & {
  name: string
  thumb: Image[]
  thumbRatio: string
  thumbWidth?: number
  productCode?: string
  index: number
  isRelated?: boolean
  settings: any
  shopData: ShopConfig
  mainData?: PrismicGeneralConfig
  parameters: any
  promotions: any
  product: Product
  addButton?: boolean
  small?: boolean
  hidePrice?: boolean
  takeback?: any
  fromPack?: boolean
  isDisplayedPrice?: boolean
  isReleatedProduct?: boolean
  isSecondHandTaxon?: boolean
  showSizesOnHover: boolean
  sizeNotShown?: boolean
  showImagesSlider: boolean
  productSettings: PrismicProduct
  productAvailability: ProductAvailability
}

const ProductCard = ({
  name,
  thumb,
  thumbRatio,
  thumbWidth,
  settings,
  productCode,
  parameters,
  promotions,
  isRelated = false,
  product,
  addButton,
  shopData,
  stock,
  small,
  index,
  cart,
  hidePrice,
  takeback,
  fromPack,
  isDisplayedPrice = true,
  isReleatedProduct = false,
  isSecondHandTaxon,
  showSizesOnHover,
  sizeNotShown,
  showImagesSlider,
  takeBackLP,
  productSettings,
  productAvailability,
}: Tprops) => {
  const {
    addProductToCart,
    changeProductQuantityInCart,
    removeProductFromCart,
  } = useCart()
  const LayoutStyle = {
    isDisplayedPrice: isDisplayedPrice || isDisplayedPrice === null,
  }
  const classes = useStyles(LayoutStyle)
  const shopClasses = useShopStyles(LayoutStyle)
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState(false)
  const { trackEvent, pushInstruction } = useMatomo()
  const router = useRouter()
  const [currentUrl, setCurrentUrl] = useState('')
  const [isCarouselActive, setIsCarouselActive] = useState(false)

  const quantity = useMemo(
    () =>
      cart?.item?.items?.find((el) => el.product?.code === productCode)
        ?.quantity || 0,
    [cart?.item?.items, productCode]
  )

  const variants = useMemo(() => {
    const _variants = []
    Object.keys(product.variants).map((variantIndex) => {
      _variants.push(product.variants[variantIndex])
    })
    return _variants.sort((a, b) => a.position - b.position)
  }, [product])

  const preventAddingToCart = settings?.prevent_adding_to_cart_without_details
  const discountPriceFormat = settings?.discounted_price_display_format
  const isSecondHand = parameters?.items?.enableTakeBack
  const isSki = product?.ski
  const shouldDisplayRetailPrice = shopData?.data?.display_retail_price
  const productIsSecondHand = product?.variants[0]?.secondHand

  useEffect(() => {
    if (router.isReady) {
      setCurrentUrl(router.asPath)
    }
  }, [router.isReady])

  const {
    isBuyableProduct,
    retailPrice,
    availability,
    secondHandAvailability,
    priceMin,
  } = useMemo(() => {
    // Le produit est marqué comme disponible si une de ses variante est disponible
    // ou si une seconde main est disponible
    // si 2nde main dispo, on affiche le prix de l'état le moins bon indépendamment de sa disponibilité
    const priceArray = []
    const keys = Object.keys(product.variants)

    const skus = Object.values(product.variants)?.map((variant) => variant?.sku)

    const _productAvailability =
      productAvailability ?? checkProductAvailability(stock, product)

    let isBuyableProduct = false
    let retailPrice = null
    for (const variant of keys) {
      const current = product.variants[variant]
      if (
        _productAvailability?.secondHandAvailable &&
        isSecondHand &&
        isSecondHandTaxon
      ) {
        let currentVariant: StockElement
        if (isReleatedProduct) {
          currentVariant = stock?.stock?.find(
            (stock) => stock?.secondHand && skus.includes(stock?.lizeeSKU)
          )
          const sku = skus.find((sku) => {
            const stockElement = stock?.stockMap?.get(sku)
            return stockElement?.secondHand
          })
          currentVariant = stock?.stockMap?.get(sku)
        } else {
          currentVariant = stock?.stockMap?.get(current?.sku)
        }
        const conditions =
          currentVariant?.secondHand &&
          currentVariant?.secondHand?.map((condition) => condition.quality)
        const lowestQualityAvailable =
          conditions &&
          currentVariant?.secondHand &&
          getLowestConditionByConditions(conditions)
        if (current?.resalePrice[lowestQualityAvailable]) {
          priceArray.push(current?.resalePrice[lowestQualityAvailable])
        }
      } else if (!product.variants[variant].purchase) {
        priceArray.push(current?.price?.current)
      } else {
        priceArray.push(
          Number(current?.purchasePrice) ? current?.purchasePrice : 0
        )
        isBuyableProduct = true
      }
      if (
        !retailPrice ||
        retailPrice?.current < current?.originalPrice?.current
      ) {
        retailPrice = current?.originalPrice?.current
      }
    }
    if (_productAvailability?.secondHandAvailable && isSecondHand) {
      isBuyableProduct = true
    }

    return {
      isBuyableProduct,
      retailPrice,
      availability: _productAvailability?.available,
      secondHandAvailability: _productAvailability?.secondHandAvailable,
      // Le prix affiché est celui du prix le plus bas entre toutes les variantes
      priceMin: Math.min(...priceArray),
    }
  }, [stock.stockMap])

  // QuantitySelector with no variant
  const onQuantityChange = useCallback(
    (quantity: number) => {
      const productInCart =
        cart &&
        cart.item &&
        cart.item.items.length > 0 &&
        typeof cart.item.items.find((el) => el.product.code === productCode) !==
          'undefined' &&
        cart.item.items.find((el) => el.product.code === productCode)

      let inStock = 0
      if (productInCart?.product?.variants[0]) {
        inStock = checkStockQuantity(stock, productInCart.product.variants[0])
      }

      const firstVariant =
        Object.keys(product.variants)[0] !== '0'
          ? Object.keys(product.variants)[0]
          : product.variants[0].code

      const response = addToCartUtility(
        firstVariant,
        quantity,
        cart,
        parameters,
        productInCart,
        false,
        inStock
      )

      if (availability) {
        const unitaryPrice = numberToPrice(
          productInCart?.product?.variants[0]?.price?.current
        )
        const totalPrice = numberToPrice(unitaryPrice * quantity)

        switch (response.message) {
          case 'limit':
          case 'max':
          case 'add':
            trackEvent({
              category: 'Add to Cart',
              action: `${name}`,
              name: `${router.pathname}`,
              value: totalPrice,
            })

            if (response.productInCart) {
              changeProductQuantityInCart(
                productInCart.id,
                { quantity },
                GAEvent.ADD_TO_CART,
                inStock
              )
              pushInstruction(
                'addEcommerceItem',
                productInCart?.product?.variants[0]?.sku,
                productInCart?.product?.name,
                productInCart?.product?.taxons?.main,
                unitaryPrice,
                quantity
              )
              pushInstruction(
                'trackEcommerceCartUpdate',
                numberToPrice(cart?.item?.totals?.total)
              )
            } else {
              if (product.isSimpleProduct) {
                addProductToCart({
                  productCode: productCode,
                  quantity: quantity,
                })
                pushInstruction(
                  'addEcommerceItem',
                  product?.variants[firstVariant]?.sku,
                  product?.name,
                  product?.taxons?.main,
                  numberToPrice(
                    product?.variants[firstVariant]?.price?.current
                  ),
                  quantity
                )
                pushInstruction(
                  'trackEcommerceCartUpdate',
                  numberToPrice(cart.item.totals.total)
                )
              } else {
                addProductToCart({
                  productCode: productCode,
                  quantity: quantity,
                  variantCode: firstVariant,
                })
              }
            }
            break
          case 'remove':
            trackEvent({
              category: 'Remove from Cart',
              action: `${name}`,
              name: `${router.pathname}`,
              value: totalPrice,
            })

            changeProductQuantityInCart(
              productInCart.id,
              { quantity },
              GAEvent.REMOVE_FROM_CART,
              inStock
            )
            pushInstruction(
              'addEcommerceItem',
              productInCart?.product?.variants[0]?.sku,
              productInCart?.product?.name,
              productInCart?.product?.taxons?.main,
              numberToPrice(
                productInCart?.product?.variants[0]?.price?.current
              ),
              quantity
            )
            pushInstruction(
              'trackEcommerceCartUpdate',
              numberToPrice(cart.item.totals.total)
            )
            break
          case 'delete':
            trackEvent({
              category: 'Delete from Cart',
              action: `${product?.name} `,
              name: `${router.pathname}`,
              value: totalPrice,
            })

            removeProductFromCart(productInCart)
            pushInstruction(
              'removeEcommerceItem',
              productInCart?.product?.variants[0]?.sku
            )
            pushInstruction(
              'trackEcommerceCartUpdate',
              numberToPrice(cart.item.totals.total)
            )
            break
          default:
            break
        }
        setOpenModal(false)
      }
    },
    [cart, productCode, product, stock, parameters, name, router]
  )

  // add product with a variant
  const addProductWithSize = useCallback(
    (variantCode, variantName, quantity) => {
      const productInCart =
        cart &&
        cart.item &&
        cart.item.items.length > 0 &&
        typeof cart.item.items.find(
          (el) => el.product.variants[0].code === variantCode
        ) !== 'undefined' &&
        cart.item.items.find(
          (el) => el.product.variants[0].code === variantCode
        )
      // check stock
      let inStock = 0
      if (productInCart) {
        inStock = checkStockQuantity(stock, productInCart.product.variants[0])
      }

      const unitaryPrice = numberToPrice(
        productInCart?.product?.variants[variantCode]?.price?.current
      )
      const totalPrice = numberToPrice(unitaryPrice * quantity)

      const response = addToCartUtility(
        variantName,
        quantity,
        cart,
        parameters,
        productInCart,
        false,
        inStock
      )

      if (response.productInCart) {
        changeProductQuantityInCart(
          productInCart.id,
          { quantity },
          GAEvent.ADD_TO_CART,
          inStock
        )
        pushInstruction(
          'addEcommerceItem',
          productInCart?.product?.variants[variantCode]?.sku,
          productInCart?.product?.name,
          productInCart?.product?.taxons?.main,
          totalPrice,
          quantity
        )
        pushInstruction(
          'trackEcommerceCartUpdate',
          numberToPrice(cart.item.totals.total)
        )
      } else {
        if (response.message === 'add') {
          trackEvent({
            category: 'Add to Cart',
            action: `${name}`,
            name: `${router.pathname}`,
            value: totalPrice,
          })
          addProductToCart({
            productCode: productCode,
            quantity: quantity,
            variantCode: variantCode,
          })
          pushInstruction(
            'addEcommerceItem',
            product?.variants[variantCode]?.sku,
            product?.name,
            product?.taxons?.main,
            unitaryPrice,
            quantity
          )
          pushInstruction(
            'trackEcommerceCartUpdate',
            numberToPrice(cart.item.totals.total)
          )
        }
      }

      setOpenModal(false)
    },
    [cart, productCode, product, stock, parameters, name, router]
  )
  const setOpenModalAndTrack = useCallback(() => {
    setOpenModal(true)
    trackEvent({
      category: 'Products',
      action: `Open Product Card modale`,
      name: `${product.name} from ${currentUrl}`,
    })
  }, [product, currentUrl])

  const thumbTemp = Array.isArray(thumb) ? thumb : [thumb]

  const hasArrows = showImagesSlider && thumbTemp?.length > 1

  // Only show first image if carousel is not active
  const displayedImages = !isCarouselActive ? thumbTemp.slice(0, 1) : thumbTemp

  const activateCarousel = useCallback(() => {
    if (!isCarouselActive) {
      setIsCarouselActive(true)
    }
  }, [hasArrows, isCarouselActive])

  return (
    <div data-testid={`${product.code}`}>
      <article
        className={clsx(
          'productCard',
          shopClasses.shopCard,
          !availability && !secondHandAvailability && classes.unavailable
        )}
        onMouseEnter={activateCarousel}
      >
        {!availability && !secondHandAvailability && (
          <Typography
            noWrap
            component="p"
            className={clsx(
              'productCard__soldout',
              small ? classes.smallAvalability : classes.availability,
              classes.unavailableTitle
            )}
            variant="body2"
          >
            <CMSText
              asText
              data={shopData?.data?.sold_out}
              defaultText={t('texts:shop:victim_of_success')}
            />
          </Typography>
        )}
        <div className={classes.carouselContainer}>
          {isCarouselActive ? (
            <Glider
              hasArrows={hasArrows}
              itemWidth={0}
              draggable
              scrollLock
              slidesToShow={1}
              iconLeft={
                <ArrowBackIos className="productCard__glider_buttons" />
              }
              iconRight={
                <ArrowForwardIos className="productCard__glider_buttons" />
              }
            >
              {displayedImages?.map(
                (image, key) =>
                  image.path && (
                    <div key={key} id={image.path} data-ratio={thumbRatio}>
                      <ImgKitHandler
                        alt=""
                        imagePaths={image}
                        classes={classes.productThumb}
                        settings={{
                          quality: 75,
                          width: thumbWidth,
                          ar: thumbRatio?.substring(3),
                        }}
                      />
                    </div>
                  )
              )}
            </Glider>
          ) : (
            displayedImages?.map(
              (image, key) =>
                image.path && (
                  <div key={key} id={image.path} data-ratio={thumbRatio}>
                    <ImgKitHandler
                      alt=""
                      imagePaths={image}
                      classes={classes.productThumb}
                      settings={{
                        quality: 75,
                        width: thumbWidth,
                        ar: thumbRatio?.substring(3),
                      }}
                    />
                  </div>
                )
            )
          )}
          {!sizeNotShown && showSizesOnHover && (
            <div className={clsx(classes.variants, 'product__variants')}>
              {variants.map((variant) => (
                <Chip
                  key={variant.code}
                  label={variant.size}
                  className={clsx(
                    classes.variantChip,
                    'sizes__btn',
                    'sizes__btn--hover_sizes'
                  )}
                  clickable={false}
                  classes={{ label: 'sizes__chip__label' }}
                />
              ))}
            </div>
          )}
        </div>

        <div className={clsx('productCard__desc', classes.description)}>
          {process.env.STORE_TYPE !== 'classic_subscription' ? (
            <>
              <div>
                <Typography
                  className={clsx('productCard__title', classes.title)}
                  variant="body1"
                  component="h2"
                >
                  <Link
                    href={{
                      pathname:
                        !isRelated || fromPack
                          ? '../products/[product]'
                          : './[product]',
                      query: { code: productCode },
                    }}
                    as={
                      !isRelated || fromPack
                        ? `../products/${productCode}`
                        : `./${productCode}`
                    }
                  >
                    <a
                      data-testid={'link-to-single'}
                      onClick={() =>
                        trackEvent({
                          category: 'Select Product',
                          action: `Select ${name} from ${currentUrl}`,
                        })
                      }
                    >
                      {small
                        ? truncateString(name, 40)
                        : truncateString(name, 80)}
                    </a>
                  </Link>
                </Typography>
              </div>
              {!hidePrice && (
                <div
                  className={clsx(
                    'productCard__rowCta',
                    classes.rowCta,
                    secondHandAvailability &&
                      isSecondHand && [
                        classes.rowCtaSecondHand,
                        'productCard__rowCta--second-hand',
                      ]
                  )}
                >
                  {priceMin !== 0 &&
                    (isDisplayedPrice || isDisplayedPrice === null) && (
                      <>
                        {secondHandAvailability && isSecondHand && (
                          <span className="productCard__price_start">
                            <CMSText
                              asText
                              data={takeback?.data?.price_start}
                              defaultText={t('texts:takeBack:price_start')}
                            />
                          </span>
                        )}
                        <Typography
                          noWrap
                          variant="body2"
                          color={'secondary'}
                          className={clsx(
                            'productCard__price',
                            classes.price,
                            secondHandAvailability &&
                              isSecondHand && [
                                classes.priceSecondHand,
                                'productCard__price--second-hand',
                              ]
                          )}
                        >
                          <ProductPriceFormat
                            promotions={promotions}
                            isSecondHand={!!productIsSecondHand}
                            avalableForRentalAndSecondHand={
                              availability && secondHandAvailability
                            }
                            isSecondHandTaxon={isSecondHandTaxon}
                            priceMin={priceMin}
                            settings={settings}
                            isBuyableProduct={isBuyableProduct}
                            discountPriceFormat={discountPriceFormat}
                            classe={'productCard'}
                          />
                          {isBuyableProduct && (
                            <BuyAbleMention
                              buyAbleMention={settings?.display_buyable_mention}
                            />
                          )}
                        </Typography>
                      </>
                    )}
                  {!preventAddingToCart && !isSki && (
                    <ProductCardAddToCart
                      hidePrice={hidePrice}
                      availability={availability || secondHandAvailability}
                      product={product as unknown as TProduct}
                      addButton={addButton}
                      classes={classes}
                      index={index}
                      setOpenModalAndTrack={setOpenModalAndTrack}
                      openModal={openModal}
                      stock={stock}
                      setOpenModal={setOpenModal}
                      shopData={shopData}
                      cart={cart}
                      addProductWithSize={addProductWithSize}
                      thumbRatio={thumbRatio}
                      thumbWidth={thumbWidth}
                      settings={settings}
                      currentUrl={currentUrl}
                      quantity={quantity}
                      onQuantityChange={onQuantityChange}
                      isSecondHandTaxon={isSecondHandTaxon}
                      takeback={takeback}
                      takeBackLP={takeBackLP}
                      productSettings={productSettings}
                    />
                  )}
                </div>
              )}

              {shouldDisplayRetailPrice && !!retailPrice && (
                <span
                  className={clsx(
                    classes.originalPrice,
                    `productCard__price--original`
                  )}
                >
                  <CMSText
                    asText
                    data={takeback?.data?.original_price}
                    defaultText={t('texts:takeBack:original_price')}
                  />
                  :&nbsp;
                  <Price amount={retailPrice} buyAble />
                </span>
              )}
            </>
          ) : (
            <>
              <div className={classes.row}>
                <Typography
                  className={classes.title}
                  variant="body1"
                  component="h2"
                >
                  <Link
                    href={'../products/[product]'}
                    as={'../products/' + productCode}
                  >
                    <a
                      data-testid={'link-to-single'}
                      onClick={() =>
                        trackEvent({
                          category: 'Select Product',
                          action: `Select ${name} from ${currentUrl}`,
                        })
                      }
                    >
                      {small
                        ? truncateString(name, 25)
                        : truncateString(name, 60)}
                    </a>
                  </Link>
                </Typography>
                {!hidePrice &&
                  (availability &&
                  product &&
                  addButton &&
                  typeof product.variants !== 'undefined' &&
                  Object.keys(product.variants).length > 1 ? (
                    <div className={clsx('productCard__add', classes.more)}>
                      <Button
                        data-testid={`buttonOpenModale-${index}`}
                        variant="contained"
                        className={
                          availability
                            ? classes.addButton
                            : classes.addButtonDisabled
                        }
                        disabled={!availability}
                        onClick={setOpenModalAndTrack}
                      >
                        <AddIcon />
                      </Button>
                      <SizeModal
                        open={openModal}
                        stock={stock}
                        handleClose={() => setOpenModal(false)}
                        products={product as unknown as TProduct}
                        text={shopData}
                        type={'product'}
                        cart={cart}
                        onQuantityChange={addProductWithSize}
                        thumbRatio={thumbRatio}
                        thumbWidth={thumbWidth}
                        settings={settings}
                        currentUrl={currentUrl}
                        isSecondHandTaxon={isSecondHandTaxon}
                        takeback={takeback}
                        takeBackLP={takeBackLP}
                        productSettings={productSettings}
                      />
                    </div>
                  ) : (
                    availability &&
                    addButton && (
                      <>
                        {settings?.has_quantity_selector ? (
                          <div
                            className={clsx('productCard__add', classes.more)}
                          >
                            <QuantitySelector
                              small
                              disable={cart.loading}
                              value={quantity}
                              handleChange={onQuantityChange}
                              min={0}
                              max={
                                product?.variants[
                                  Object.keys(product?.variants)[0]
                                ].maxStock
                                  ? product?.variants[
                                      Object.keys(product?.variants)[0]
                                    ].maxStock
                                  : null
                              }
                              variantSku={
                                product?.variants[
                                  Object.keys(product?.variants)[0]
                                ].sku
                              }
                            />
                          </div>
                        ) : (
                          <>
                            <div
                              className={clsx('productCard__add', classes.more)}
                            >
                              <Button
                                data-testid={`buttonOpenModale-${index}`}
                                variant="contained"
                                className={
                                  availability
                                    ? classes.addButton
                                    : classes.addButtonDisabled
                                }
                                disabled={!availability}
                                onClick={() => onQuantityChange(1)}
                              >
                                <AddIcon />
                              </Button>
                            </div>
                          </>
                        )}
                      </>
                    )
                  ))}
              </div>
              {!addButton && small && (
                <div className={classes.fixedHeightSmall}></div>
              )}
            </>
          )}
        </div>
      </article>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  cart: state.cart,
  stock: state.stock,
  parameters: state.parameters,
  promotions: state.promotions,
  takeBackLP: state.takeBack,
})

export default connect(mapStateToProps)(ProductCard)
