import {
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Typography,
  Tooltip,
} from '@material-ui/core'
import React from 'react'
import clsx from 'clsx'
import CMSText from '../Global/CMSText'
import ProductPriceFormat from '../Shop/ProductPriceFormat'
import BuyAbleMention from '../Shop/BuyAbleMention'
import Price from '../Shop/Price'
import InfoIcon from '@material-ui/icons/Info'
import { isSecondHandOnly } from '../../utils/constants'
import { useTranslation } from 'react-i18next'

type Tprops = {
  productLayout: any
  isMobile: boolean
  currentItem: any
  addFromPack: any
  promotions: any
  minPrice: number
  takeBackPrice: number
  main: any
  isBuyableProduct: boolean
  discountPriceFormat: any
  selectedTakeBackOrRental?: number
  takeback: any
  displayFlexPrice: boolean
  isDisplayTotalRentPrice: boolean
  productSettings: any
  dayRange: any
  currentPromotion: any
  selectedQuality?: string
  originalPrice?: number
  takeBackPriceMin?: number
  takeBackAvailability?: boolean
  isDisplayedPrice?: boolean
  isSecondHand?: boolean
  isModal?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    head: {
      marginBottom: theme.spacing(2),
    },
    headNoPaddingTop: {
      paddingTop: 0,
    },
    headSticky: {
      marginBottom: theme.spacing(0.5),
    },
    secondHandHead: {
      '& .MuiGrid-item': {
        maxWidth: '100%',
        flexBasis: '100%',
      },
    },
    rentalDuration: {
      display: 'flex',
      '& .paragraph-class': {
        margin: 0,
      },
    },
    content: {
      marginTop: theme.spacing(2),
    },
    contentSticky: {
      [theme.breakpoints.down('md')]: {
        zIndex: theme.zIndex.snackbar,
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: theme.spacing(2, 2, 1),
        background: theme.palette.common.white,
        borderTop: `1px solid ${theme.extraColors.greyLight}`,
      },
      [theme.breakpoints.up('md')]: {
        position: 'sticky',
        top: theme.sizes.headerHeight + theme.spacing(2),
      },
    },
    contentDetailsSticky: {
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
      },
    },
    desc: {
      marginBottom: theme.spacing(2),
    },
    verticalAlign: {
      display: 'inline-flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '&>.MuiSvgIcon-root': {
        marginRight: theme.spacing(1),
      },
    },
  })
)

const ProductTitle = ({
  productLayout,
  isMobile,
  currentItem,
  addFromPack,
  promotions,
  minPrice,
  takeBackPrice,
  main,
  isBuyableProduct,
  discountPriceFormat,
  selectedTakeBackOrRental,
  takeback,
  displayFlexPrice,
  isDisplayTotalRentPrice,
  productSettings,
  dayRange,
  currentPromotion,
  selectedQuality,
  originalPrice,
  takeBackPriceMin,
  takeBackAvailability,
  isDisplayedPrice = true,
  isSecondHand,
  isModal = false,
}: Tprops) => {
  const productClasses = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <Grid
        className={clsx('product__head', productClasses.head, {
          [productClasses.headNoPaddingTop]: isModal,
          ['product__head__modal']: isModal,
          [productClasses.headSticky]: productLayout && isMobile,
          [productClasses.secondHandHead]:
            selectedTakeBackOrRental === 1 ||
            (isSecondHand && !isSecondHandOnly),
        })}
        alignItems="center"
        container
        spacing={2}
      >
        <Grid item sm="auto">
          <Typography
            variant="h3"
            component="h1"
            className={clsx('product__title')}
          >
            {currentItem.name}
          </Typography>
          {selectedTakeBackOrRental === 1 && (
            <Typography
              variant="h4"
              component="div"
              className={clsx('product__state')}
            >
              <CMSText
                asText
                data={takeback?.data?.second_hand_label}
                defaultText={t('texts:takeBack:second_hand_label')}
              />
            </Typography>
          )}
        </Grid>
        {process.env.STORE_TYPE !== 'classic_subscription' &&
          (isDisplayedPrice || isDisplayedPrice === null) && (
            <Grid item sm="auto">
              {currentItem && currentItem?.variants && !addFromPack && (
                <Typography
                  variant={isMobile ? 'h4' : 'h3'}
                  component="div"
                  className={clsx(
                    productClasses.verticalAlign,
                    'product__price'
                  )}
                >
                  <ProductPriceFormat
                    promotions={promotions}
                    priceMin={minPrice}
                    takeBackPrice={takeBackPrice}
                    settings={main?.data}
                    isBuyableProduct={isBuyableProduct}
                    discountPriceFormat={discountPriceFormat}
                    isDisplayPercentage
                    classe={'product'}
                    selectedTakeBackOrRental={selectedTakeBackOrRental}
                    takeback={takeback}
                    selectedQuality={selectedQuality}
                    originalPrice={originalPrice}
                    productSettings={productSettings}
                    takeBackPriceMin={takeBackPriceMin}
                    takeBackAvailability={takeBackAvailability}
                    variants={Object.values(currentItem.variants)}
                  />

                  {isBuyableProduct && (
                    <BuyAbleMention
                      buyAbleMention={main?.data?.display_buyable_mention}
                    />
                  )}
                  {displayFlexPrice && !isBuyableProduct && (
                    <>
                      &nbsp;
                      <span
                        className={clsx(
                          productClasses.verticalAlign,
                          'product__information'
                        )}
                      >
                        <Tooltip
                          title={t('texts:general:flex_price_information')}
                          placement="top"
                        >
                          <InfoIcon fontSize="small" />
                        </Tooltip>
                      </span>
                    </>
                  )}
                </Typography>
              )}
            </Grid>
          )}
      </Grid>
      {isDisplayTotalRentPrice && !isBuyableProduct && (
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Typography
              className={clsx(
                'product__rent-total',
                productClasses.rentalDuration
              )}
              component={'div'}
            >
              <CMSText data={productSettings?.data?.total_rental_price_text} />
              &nbsp;
              <Price
                amount={
                  currentPromotion
                    ? (minPrice - (minPrice * currentPromotion) / 100) *
                      dayRange
                    : minPrice * dayRange
                }
                buyAble={isBuyableProduct}
              />
              &nbsp;
              <CMSText
                asText
                data={productSettings?.data?.total_rental_price_for}
                defaultText={t('texts:basket:for_short')}
              />
              &nbsp;
              {dayRange}
              &nbsp;
              <CMSText
                asText
                data={
                  dayRange > 1
                    ? productSettings?.data?.total_rental_price_day_plural
                    : productSettings?.data?.total_rental_price_day_singular
                }
                defaultText={
                  dayRange > 1
                    ? t('texts:general:days_plural')
                    : t('texts:general:day_singular')
                }
              />
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ProductTitle
